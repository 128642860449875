import {useState, useEffect, useContext, useRef, useReducer} from "react"
import { Button, Form, Row, Col, Modal, Table, Toast, ToastContainer, InputGroup } from "react-bootstrap"
import { useForm } from "react-hook-form";
import axios from "axios";
import ClientContext from "../../../Context/ClientContext";
import {BiClipboard, BiEditAlt, BiTrash} from 'react-icons/bi'
import { BarLoader } from "react-spinners";
import {HiOutlineVideoCamera} from 'react-icons/hi'
import ReactPlayer from 'react-player';
import './videos.css';
import UserContext from "../../../Context/UserContext";

export default function Videos() {
const API_URL = 'https://api.medicionanalytic.com/api'
const {logData} = useContext(ClientContext)
const {userData} = useContext(UserContext)
const [_1, forceUpdate] = useReducer(x => x + 1, 0);
const { register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty, isValid },
      } = useForm({ mode: "onBlur" });

const [videos, setVideos] = useState([])
const [preguntas, setPreg] = useState([])
const [dataVid, setDataVid] = useState([])
const [toast, setToast] = useState({ show: false, variant: "", message: "" });

const [videoCreate, setvideoCreate] = useState(false)
const [videoEdit, setVideoE] = useState(false)
const [videoView, setVideoV] = useState(false)
const [videoEva, setVE] = useState(false)
const [videoElim, setElim] = useState(false)
const [consulta, setConsulta] = useState(false)

const [load, setLoad] = useState(false)

const [periodos, setPeriodos] = useState([])
const [encuestas, setEncuestas] = useState([])
const [Basencuestas, setBaseEncuestas] = useState([])
const [joints, setJoints] = useState([])

const [secciones, setSecciones] = useState([])
const [puntos, setPuntos] = useState([])
const [cities, setCities] = useState([])
const [Sucursales, setSuc] = useState([]) //filtro de sucursales.

const [respuestas, setRespuestas] = useState([])

const [Secciones, setS] = useState([])
const [enc, setE] = useState()
const [sSec, setSsec] = useState(0)
const [sPeriod, setSP] = useState(0)
const [sSuc, setSS] = useState(0)

const [FV, setFV] = useState([])

const showEliminar = (video) => {    
    setValue("encuesta_id", video.encuesta_id)
    setValue("video_id", video.id)
    setValue("client_id", logData.id)
    setValue("estado", video.estado)
    setValue("nombre_video", video.nombre_video)
    setValue("link_video", video.link_video)
    setElim(true)
}
const hideEliminar = () => setElim(false)
const showVideo = () => setvideoCreate(true)
const hideVideo = () => setvideoCreate(false)
const showView = (video) => {
    setValue("video_id", video.id)
    setValue("client_id", logData.id)
    setValue("nombre_video", video.nombre_video)
    setValue("link_video", video.link_video)
    setValue("resultado", video.resultado)
    setVideoV(true)}
const hideView = () => {setVideoV(false);reset({video_id:"",reporte_id:"",nombre_video:"",link_video:"", resultado:""})}
const showEdit = (video) => {    
    reset({
        video_id: video.id,
        link_video: video.link_video,
        nombre_video: video.nombre_video,
        estado: video.estado,
    })
    setVideoE(true)}
const hideEdit = () => {setVideoE(false);reset({video_id:"",reporte_id:"",nombre_video:"",link_video:"", resultado:""})}
const showEva = (video) => {
    setValue("encuesta_id", video.encuesta_id)
    setValue("video_id", video.id)
    setValue("client_id", logData.id)
    setValue("estado", video.estado)
    setValue("nombre_video", video.nombre_video)
    setValue("link_video", video.link_video)
    setVE(true)}
const hideEva = () => {setVE(false);reset({video_id:"",reporte_id:"",nombre_video:"",link_video:"", resultado:""})}

useEffect(() => {
    axios.get(API_URL + '/video', { params: { client_id: Number(logData.id)} }).then(response => {setVideos(response.data);showData();})
    axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                 
    axios.get(API_URL + '/pregunta/all', {params: {client_id: Number(logData.id)}}).then((res)=>{
        let numbered = res.data.filter(p => Number(p.orden) > 0)
        let unnumbered = res.data.filter(p => Number(p.orden <= 0))
        numbered = numbered.sort((a, b) => Number(a.orden) > Number(b.orden) ? 1 : -1)
        let preg = [...numbered,...unnumbered]                
        setPreg(preg)
    })    
    axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(response => {response.data.forEach(p => {p.label = p.nombre_periodo;p.value = p.id;});
    response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
    setPeriodos(response.data)})
    axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
    axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
        res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
        let points = userData.permiso === "admin" ? res.data : res.data.filter(el => {return userData.puntos.split(",").indexOf(el.id.toString()) > -1})        
        if(logData.id === 49) {setPuntos(points)} else {setSuc(points)}                       
    })
    axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then((response) => {response.data.forEach(p => {p.label = p.nombre_seccion;p.value = p.id;});setSecciones(response.data)})
    axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {        
        let n;
        let temp;
        if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
        else { temp = response.data }
        if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "mystery");}
        else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "mystery");}
        setBaseEncuestas(n)                
    })
},[toast])

const setHeaders = () => {
    let token = window.localStorage.getItem("sessionKey");
    const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
    };
    return headers;
    }

const CreateVideo = (data,e) => {
    setLoad(true)
    e.preventDefault()    
    data.encuesta_id = enc.id
    data.seccion_id = Number(sSec)
    data.periodo_id = Number(sPeriod)
    data.punto_id = Number(sSuc)
    axios.post(API_URL + "/video", data).then(res => {
        Toaster("success", res.data.message)
        setLoad(false)
        reset({video_id:"",reporte_id:"",nombre_video:"",link_video:"", resultado:""})        
    }).catch(err => {
        Toaster("danger", "Ha ocurrido un error")
        setLoad(false)
    })
}

const EditVideo = (data,e) => {    
    setLoad(true)
    e.preventDefault()
    let headers = setHeaders();
    axios.post(API_URL + "/video/update", data, {headers}).then(res => {
        Toaster("success", res.data.message)        
        hideEdit()        
        setLoad(false)
    })
}

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

const showData = () => {        
    if (sPeriod > 0 && sSec > 0 && sSuc > 0)
        {   let temp = videos.filter(v => v.periodo_id === Number(sPeriod) &&
            v.seccion_id === Number(sSec) &&
            v.encuesta_id === Number(enc.id) && 
            v.punto_id === Number(sSuc))        
        setFV(temp)          
        setConsulta(true)
    }
    else {
        setFV([])
    }
}

const CreateReport = (data, e) => {        
    respuestas.forEach(r => { r.periodo_id = sPeriod; r.seccion_id = sSec; r.punto_id = sSuc; if(r.id) {delete r.id} })
    setLoad(true)    
    CalculateResult()
    Cleanse()    
}

const Cleanse = () => {    
    let headers = setHeaders()        
    axios.delete(API_URL+`/respuesta/${getValues("video_id")}`, {headers}).then((res) => {
        setTimeout(()=>{
            axios.post(API_URL + "/respuesta", respuestas).then((res) => {                
                reset({video_id:"",reporte_id:"",nombre_video:"",link_video:"", resultado:""})
                setLoad(false)
                forceUpdate()
            })                    
        },3000)
        hideEva()
    })    
}

const CleanseD = () => {    
    let headers = setHeaders()
    axios.delete(API_URL+`/respuesta/${getValues("video_id")}`, {headers})    
}

const loadNewReport = () => {
    let dats = {};
    dats.periodo_id = Number(sPeriod)
    dats.encuesta_id = Number(enc.id)
    dats.sucursal = Number(sSuc)
    dats.video_id = getValues("video_id")
    dats.tipo_encuesta = "mystery"
    axios.post(API_URL + '/reporte', dats).then(res => { setValue("reporte_id", res.data.id) })
}

const addValue = () => {    
    let line = getValues()
    let res = respuestas
    line.reporte_id = getValues("reporte_id")
    line.tipo_encuesta = "mystery"
    let position = res.findIndex(r => r.pregunta_id === line.pregunta_id)
    if(position === -1)
    {setRespuestas([...res, line]);}
    else {
        res[res.findIndex(r => r.pregunta_id === line.pregunta_id)] = line
        setRespuestas(res)
    }        
}

function CalculateResult(){
let sum = []
let headers = setHeaders();
    respuestas.forEach(r => {
        if(r.tipo_pregunta === "escala") { sum.push({value:(r.respuesta*100)/r.valor_max} )}
        if(r.tipo_pregunta === "thumbs") { if(r.respuesta === "si") {sum.push({value:100})} else if(r.respuesta === "no"){sum.push({value:0})}}
        if(r.tipo_pregunta === "nps"){ sum.push({value:(r.respuesta*100)/10})}        
        if(r.tipo_pregunta === "smiles"){sum.push({value :(r.respuesta*100)/5})}
        if(r.tipo_pregunta === "escala-tri") {
            if(r.respuesta === "malo") {sum.push({value:0})}
            else if(r.respuesta === "regular") {sum.push({value:50})}
            else if(r.respuesta === "excelente") {sum.push({value:100})}}
    })
    let temp = (sum.reduce(function (acc, obj) { return acc + obj.value; }, 0)/sum.length).toFixed(2) + '%'
    let formData = new FormData();
    formData.append("video_id", getValues("video_id"))
    formData.append("resultado", temp)
axios.post(API_URL + '/video/updateR', formData, {headers}).then(res => {
    Toaster("success", res.data.message)        
    
})
}

function renderSwitch(preg, number) {
        let temp = preg              
        let vals = []
        let resp
        if(getValues("video_id") !== undefined){ 
            vals = dataVid.filter(d => {return d.video_id === getValues("video_id")})
            resp = vals.findIndex(v => (v.pregunta_id === temp.id) && v.tipo_pregunta === temp.tipo_pregunta)                            
        }
        switch (temp.tipo_pregunta) {
            case 'escala-tri':                                                                      
                    return <>
                            <Form.Label>{number+". "+temp.titulo}</Form.Label>
                            <InputGroup className="mt-2" onClick={(e) => {                                
                                for(let i = 0; i < e.target.parentNode.children.length; i++) {
                                    if(e.target.parentNode.children[i].classList.contains('btn-outline-danger-active')) {e.target.parentNode.children[i].classList.remove('btn-outline-danger-active');}
                                    if(e.target.parentNode.children[i].classList.contains('btn-outline-warning-active')) {e.target.parentNode.children[i].classList.remove('btn-outline-warning-active');}
                                    if(e.target.parentNode.children[i].classList.contains('btn-outline-success-active')) {e.target.parentNode.children[i].classList.remove('btn-outline-success-active');}                                    
                                }                                                                                                
                                e.target.classList.add(e.target.classList[1]+'-active')                                
                            }}>
                                <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === "malo" ? "btn-outline-danger-active":""}
                                 variant="outline-danger" type="submit" onClick={() => {
                                     setValue("respuesta", "malo");
                                    setValue("tipo_pregunta", temp.tipo_pregunta);
                                    setValue("pregunta_id", temp.id);
                                    setValue("pregunta", temp.titulo);
                                    addValue();
                                }
                                    }>malo</Button>
                                <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === "regular" ? "btn-outline-warning-active":""}
                                variant="outline-warning" type="submit" onClick={() => {
                                    setValue("respuesta", "regular");
                                    setValue("tipo_pregunta", temp.tipo_pregunta);
                                    setValue("pregunta_id", temp.id);
                                    setValue("pregunta", temp.titulo);
                                    addValue();
                                }
                                    }>regular</Button>
                                <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === "excelente" ? "btn-outline-success-active":""}
                                variant="outline-success" type="submit" onClick={() => {
                                    setValue("respuesta", "excelente");
                                    setValue("tipo_pregunta", temp.tipo_pregunta);
                                    setValue("pregunta_id", temp.id);
                                    setValue("pregunta", temp.titulo);
                                    addValue();
                                }
                                    }>excelente</Button>
                            </InputGroup>
                    </>
            case 'escala':
                let escala = [...Array(temp.valor_max).keys()]
                return <>
                    <Form.Label className="m-2 mt-0">{number+". "+temp.titulo}</Form.Label>
                    <InputGroup onClick={(e) => {
                        for(let i = 0; i < e.target.parentNode.children.length; i++) {
                            e.target.parentNode.children[i].classList.remove("btn-active")
                        }
                        if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}
                    }}>
                        {
                            escala.map((number) => <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === number+1 ? "btn-active":""}
                             onClick={(e) => {
                                setValue('respuesta', number + 1);
                                setValue("tipo_pregunta", temp.tipo_pregunta);
                                setValue("pregunta_id", temp.id);
                                setValue("pregunta", temp.titulo);
                                setValue("valor_max", temp.valor_max);
                                addValue();
                            }}>{number + 1}</Button>)
                        }
                    </InputGroup>
                </>
            case 'thumbs':                
                return <>                    
                    <Form.Label>{number+". "+temp.titulo}</Form.Label>
                    <InputGroup className="m-2 mt-0 mb-0"                     
                    onClick={(e) => {                        
                        for(let i = 0; i < e.target.parentNode.children.length; i++) {
                            e.target.parentNode.children[i].classList.remove("btn-active")
                        }
                        if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}
                    }}>
                        <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === "si" ? "btn-active":""}                            
                        onClick={(e) => {                            
                            setValue("respuesta", "si");
                            setValue("tipo_pregunta", temp.tipo_pregunta);
                            setValue("pregunta_id", temp.id);
                            setValue("pregunta", temp.titulo);
                            addValue();
                            }}>Si</Button>
                        <Button className={vals.length > 0 && vals[resp] !== undefined &&  vals[resp].respuesta === "no" ? "btn-active":""}
                         onClick={(e) => {                            
                            setValue("respuesta", "no");
                            setValue("tipo_pregunta", temp.tipo_pregunta);
                            setValue("pregunta_id", temp.id);
                            setValue("pregunta", temp.titulo);
                            addValue();
                        }}>No</Button>                                                       
                        <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === "na" ? "btn-active":""}
                         onClick={(e) => {                            
                            setValue("respuesta", "na");
                            setValue("tipo_pregunta", temp.tipo_pregunta);
                            setValue("pregunta_id", temp.id);
                            setValue("pregunta", temp.titulo);
                            addValue();
                        }}>N/A</Button>                                                       
                    </InputGroup>
                </>
            case 'smiles':
                let smiles = [...Array(5).keys()]
                return <>
                    <Form.Label className="m-2 mt-0">{number+". "+temp.titulo}</Form.Label>
                    <InputGroup onClick={(e) => {
                        for(let i = 0; i < e.target.parentNode.children.length; i++) {
                            e.target.parentNode.children[i].classList.remove("btn-active")
                        }
                        if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}
                    }}>
                        {
                            smiles.map((number) => <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === number+1 ? "btn-active":""}
                             onClick={(e) => {
                                setValue('respuesta', number + 1);
                                setValue("tipo_pregunta", temp.tipo_pregunta);
                                setValue("pregunta_id", temp.id);
                                setValue("pregunta", temp.titulo);
                                setValue("valor_max", temp.valor_max);
                                addValue()
                            }}>{number + 1}</Button>)
                        }
                    </InputGroup>
                </>
            case 'stars':
                let escala2 = [...Array(5).keys()]
                return <>
                    <Form.Label className="m-2 mt-0">{number+". "+temp.titulo}</Form.Label>
                    <InputGroup onClick={(e) => {
                        for(let i = 0; i < e.target.parentNode.children.length; i++) {
                            e.target.parentNode.children[i].classList.remove("btn-active")
                        }
                        if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}
                    }}>
                        {
                            escala2.map((number) => <Button className={ vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === number+1 ? "btn-active":""}
                            onClick={(e) => {
                                setValue('respuesta', number + 1);
                                setValue("tipo_pregunta", temp.tipo_pregunta);
                                setValue("pregunta_id", temp.id);
                                setValue("pregunta", temp.titulo);
                                setValue("valor_max", temp.valor_max);
                                addValue()
                            }}>{number + 1}</Button>)
                        }
                    </InputGroup>
                </>
            case 'nps':
                let escala3 = [...Array(10).keys()]
                return <>
                    <Form.Label className="m-2 mt-0">{number+". "+temp.titulo}</Form.Label>
                    <InputGroup onClick={(e) => {
                        for(let i = 0; i < e.target.parentNode.children.length; i++) {
                            e.target.parentNode.children[i].classList.remove("btn-active")
                        }
                        if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}
                    }}>
                        {
                            escala3.map((number) => <Button className={vals.length > 0 && vals[resp] !== undefined && vals[resp].respuesta === number+1 ? "btn-active":""}
                             onClick={(e) => {
                                setValue('respuesta', number + 1);
                                setValue("tipo_pregunta", temp.tipo_pregunta);
                                setValue("pregunta_id", temp.id);
                                setValue("pregunta", temp.titulo);
                                setValue("valor_max", temp.valor_max);
                                addValue()
                            }}>{number + 1}</Button>)
                        }
                    </InputGroup>
                </>
            case 'select': return <>not yet implemented</>
            case 'selectm': return <>not yet implemented</>
            case 'info': return <>not yet implemented</>            
            case 'load': return <>not yet implemented</>
            case 'email': return <>not yet implemented</>
            default:
                return <Form.Group>
                    <Form.Label className="m-2 mt-0">{number+". "+temp.titulo}</Form.Label>
                    <InputGroup>
                        <Form.Control onChange={(e) => {
                            for(let i = 0; i < e.target.parentNode.children.length; i++) {
                                e.target.parentNode.children[i].classList.remove("btn-active")
                            }
                        }} type="text" name="respuesta" defaultValue={vals.length > 0 && vals[resp] !== undefined ? vals[resp].respuesta : null} as={temp.as} rows={temp.rows} />
                        <Button onClick={(e) => {                            
                            e.target.classList.add('btn-active');
                            setValue("respuesta", e.target.parentNode.children[0].value);
                            setValue("tipo_pregunta", temp.tipo_pregunta);                            
                            setValue("pregunta_id", temp.id)
                            setValue("pregunta", temp.titulo);
                            addValue();
                            }}>fijar</Button>
                    </InputGroup>
                </Form.Group>
        }
}

const handleEncuesta = (e) => {
    setLoad(true)
    e.preventDefault()
    setValue("seccion_id", null)
    let sec = secciones
    let en = encuestas.find(el => el.id === Number(e.target.value))
    let tem = sec.filter(s => {return s.encuesta_id === en.id})
    axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value) } }).then(res => {        
        setDataVid(res.data.filter(p => p.video_id !== null));
        setLoad(false)
    })    
    setE(en)
    setS(tem)    
}
const handlePerdiod = (e) => {    
    setSP(Number(e.target.value))
    setEncuestas([])    
    if(e && e.target.value !== null && e.target.value !== undefined) {        
    let temp = joints.filter(j => { return Number(j.periodo_id) === Number(e.target.value) })
    let temp2 = Basencuestas.filter(e => { return temp.some(j => {return Number(j.encuesta_id) === Number(e.id) }) })      
    setEncuestas(temp2)
    }
  }  
const handleCity = (e) => {  
    e.preventDefault()           
    let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
    setSuc(sucursales)
}
const Remove = () => {
    setLoad(true)    
    let headers = setHeaders()
    CleanseD()        
    axios.delete(API_URL + `/video/${getValues("video_id")}`, {headers}).then(res => {
        Toaster("success", res.data.message)
        hideEliminar()
        setLoad(false)
    })
}
const FitRespuestas = (video) => {            
    if(video.id !== undefined){         
        let vals = dataVid.filter(d => {return d.video_id === video.id})                 
        setRespuestas(vals)        
    }
    else {setRespuestas([])}
}

return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={videoCreate} onHide={hideVideo}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Video</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CreateVideo)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del video</Form.Label>
                        <Form.Control {...register("nombre_video")} />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                    <Form.Label>Dirección del enlace</Form.Label>
                    <Form.Control {...register("link_video", {required:true})} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideVideo}>
                Cerrar
            </Button>
            <Button type="submit" disabled={load||!isValid} className="App-button" onClick={hideVideo}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={videoEdit} onHide={hideEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Video</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditVideo)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del video</Form.Label>
                        <Form.Control {...register("nombre_video")} />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                    <Form.Label>Dirección del enlace</Form.Label>
                    <Form.Control {...register("link_video")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>                        
            <Button className="App-button-alt" onClick={hideEdit}>
                Cerrar
            </Button>
            <Button type="submit" disabled={load} className="App-button">
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={videoEva} onHide={hideEva} centered fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Evaluar Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                    <div style={{position:'fixed',width:"45vw"}} className="p-2">
                        <ReactPlayer url={getValues("link_video")}  loop={true} controls={true} width={"100%"} />
                    </div>                    
                </Col>
                <Col>
                    <div className="video-form">
                        <div className="top-bubble"></div>                        
                        {preguntas.filter(p => p.seccion_id === Number(sSec))
                            .map((p, index) => {
                                return <Row className="m-2">
                                    {renderSwitch(p,index+1)}
                                </Row>
                            })
                        }
                    <div className="bottom-bubble"></div>
                    </div>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>            
            {preguntas.filter(p => p.seccion_id === Number(sSec)).length > respuestas.length ? <p style={{position:"absolute",left:"10px"}}>Aun hay campos por llenar en la evaluación.</p> : null}        
            <Button className="App-button-alt" onClick={hideEva}>
                Cerrar
            </Button>            
            <Button type="submit" className="App-button" disabled={preguntas.filter(p => p.seccion_id === Number(sSec)).length > respuestas.length ? true: false} onClick={() => {                                
                setValue("tipo_encuesta", "mystery")
                CreateReport()
                }}>
                Actualizar
            </Button>
            </Modal.Footer>
    </Modal>
    <Modal show={videoView} onHide={hideView} centered fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Ver Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                    <div style={{position:'fixed',width:"45vw"}} className="p-2">
                        <ReactPlayer url={getValues("link_video")}  loop={true} controls={true} width={"100%"} />
                    </div>                    
                </Col>
                <Col className="hidden-sm">
                    <div className="video-form">
                        <div className="top-bubble"></div>
                        {dataVid ? preguntas.filter(p => p.seccion_id === Number(sSec))
                        .map((p,index) => {                                                                                   
                                return <Row className="m-2">
                                    <Row>{index+1+". "+p.titulo}</Row>                                    
                                    <Row className="video-response">{ dataVid.find(item => { return Number(item.video_id) === Number(getValues("video_id")) && Number(item.pregunta_id) === Number(p.id) }) ? 
                                    dataVid.find(item => { return Number(item.video_id) === Number(getValues("video_id")) && Number(item.pregunta_id) === Number(p.id)}).respuesta : null }</Row>
                                </Row>
                            }): null
                        }
                    <div className="bottom-bubble"></div>
                    </div>
                    <div className="m-3 result-display">
                        <h5>Resultado:</h5>
                        <h3 className="m-2 mt-0 mb-0">{getValues("resultado")}</h3>
                    </div>
                </Col>
            </Row>
            <Row> 
                <Col className="hidden-lg mt-2">
                    <div className="video-form">
                        <div className="top-bubble"></div>
                        {dataVid ? dataVid.filter(item => item.video_id === Number(getValues("video_id")))
                        .map(p => {
                                return <Row className="m-2">
                                    <Row>{p.pregunta}</Row>
                                    <Row className="video-response">{p.respuesta}</Row>
                                </Row>
                            })
                        : null}
                    <div className="bottom-bubble"></div>
                    </div>
                    <div className="m-3 result-display">
                        <h5>Resultado:</h5>
                        <h3 className="m-2 mt-0 mb-0">{getValues("resultado")}</h3>
                    </div>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button className="App-button-alt" onClick={hideView}>
                Cerrar
            </Button>
            </Modal.Footer>
    </Modal>
    <Modal show={videoElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Video</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button" disabled={load} onClick={() => Remove()} >Eliminar</Button>
            </Modal.Footer>
    </Modal>
    <div className="content-container">
    <Form>
                        <Row className="mb-1">
                            <Col>
                                <Form.Select onChange={handlePerdiod}>
                                    <option value={null}>Sin Periodo Seleccionado</option>
                                    {periodos.map(enc => {return <option value={enc.id}>{enc.nombre_periodo}</option>})}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>})}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                            {logData.id === 49 ? 
                                <Form.Select onChange={handleCity} >
                                    <option value={null}>Sin Ciudad Seleccionada</option>
                                    {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                                </Form.Select>:
                                <Form.Select onChange={(e) => setSsec(e.target.value)}>
                                    <option value={null}>Sin Seccion Seleccionada</option>
                                    {Secciones.map(enc => {return <option value={enc.id}>{enc.nombre_seccion}</option>})}
                                </Form.Select> }
                            </Col>
                            <Col>
                                <Form.Select onChange={(e) => setSS(e.target.value)}>
                                    <option value={null}>Sin Punto Seleccionado</option>
                                    {Sucursales.map(enc => {return <option value={enc.id}>{enc.nombre_sucursal}</option>})}
                                </Form.Select>
                            </Col>
                        </Row>
                        {logData.id === 49 ? <Row className="mb-1">
                            <Col>
                                <Form.Select onChange={(e) => setSsec(e.target.value)}>
                                    <option value={null}>Sin Seccion Seleccionada</option>
                                    {Secciones.map(enc => {return <option value={enc.id}>{enc.nombre_seccion}</option>})}
                                </Form.Select>
                            </Col>
                        </Row>: null}
                        <Row className="m-1 mt-3">                            
                            <Button className="App-button m-2 mt-0" disabled = {load} onClick={() => {showData()}}>
                                {load ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Mostrar videos</> }
                            </Button>
                        </Row>
    </Form>
        {consulta ?
        <div className="content-body-alt">
            <div className="content-header"><h4>Videos</h4></div>
            <Button className="m-3 App-button" onClick={showVideo}> Nuevo video </Button>
            <Table className="App-Table" striped responsive="md" hover size="sm">
                <thead>
                    <tr>                        
                        <th className="table-th">nombre</th>
                        <th className="table-th">punto</th>
                        <th className="table-th">seccion</th>
                        <th className="table-th">resultado</th>
                        <th className="table-th">estado</th>                        
                        <th className="table-th">acciones</th>                        
                    </tr>
                </thead>
                <tbody>
                        {FV.map((video, index) => 
                           <tr>                                
                                <td>{video.nombre_video}</td>
                                {Sucursales.length > 0 ? <td>{Sucursales.find(p => p.id === video.punto_id).nombre_sucursal}</td> : <td></td>}
                                {secciones.length > 0 ? <td>{secciones.find(p => p.id === video.seccion_id).nombre_seccion}</td>: <td></td> }
                                <td>{video.resultado}</td>
                                <td>{video.estado}</td>
                                <td className="clickable-end">
                                        <td className="clickable" onClick={() => {showView(video)}}><HiOutlineVideoCamera /></td> {/* ver */}
                                        <td className="clickable" onClick={() => {showEdit(video)}}><BiEditAlt /></td> {/* editar */}
                                        <td className="clickable" onClick={() => {showEva(video);loadNewReport();FitRespuestas(video)}}><BiClipboard /></td> {/* recalificar */}
                                        <td className="clickable" onClick={() => {showEliminar(video)}}><BiTrash /></td>
                                </td>
                            </tr>
                        )}
                </tbody>
            </Table>
          </div>: null}
    </div>
    </>)
}